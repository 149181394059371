import { combineReducers } from "redux";
import user from "./user_reducer";
import app from './app';
import localfilemanager from './localfilemanager';

const Reducer = combineReducers({
  user,
  app,
  localfilemanager
});

export default Reducer;
